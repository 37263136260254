import * as React from "react";
import { List, Typography } from "@mui/material";

export function PostTags(props: { parentIndex: number; tags: string[] }) {
  return (
    <List sx={{ display: "flex", paddingLeft: "16px" }}>
      {props.tags.map((tag: string, index: number) => {
        return (
          <Typography
            variant="subtitle2"
            color={index % 2 === 0 ? "primary.main" : "secondary.main"}
            sx={{ fontWeight: "bold", lineHeight: "1", marginRight: ".3rem" }}
            key={index}
          >
            {tag}
          </Typography>
        );
      })}
    </List>
  );
}
