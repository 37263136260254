import * as React from "react";
import { ArrowCircleRightOutlined } from "@mui/icons-material";
import {
  Button,
  Box,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Typography,
} from "@mui/material";
import { PostTags } from "./PostTags";
import { ContentProps } from "../../common/types";

interface Props {
  posts: Array<ContentProps>;
}

export function Posts(props: Props) {
  const [limit, setLimit] = React.useState(5);
  const [loadMoreUsed, setLoadMoreUsed] = React.useState(false);
  const showMorePosts = () => {
    setLimit(limit + 5);
    setLoadMoreUsed(true);
  };
  const showLessPosts = () => {
    setLimit(limit - 5);
  };

  return (
    <>
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "0",
        }}
      >
        {props.posts.slice(0, limit).map((post: ContentProps, i: number) => {
          return (
            <ListItemButton component="a" href={`${post.link}`} key={i}>
              <Paper
                sx={{ maxWidth: "40rem", minWidth: "22rem" }}
                elevation={1}
              >
                <Box
                  sx={{
                    bgcolor: "primary.main",
                    height: ".25rem",
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                  }}
                ></Box>
                <Box sx={{ bgcolor: "secondary.main", height: ".5rem" }}></Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: ".5rem",
                  }}
                >
                  <Paper
                    alt={`${post.ctaAltText}`}
                    component="img"
                    src={`${post.ctaImage}`}
                    sx={{ width: "100%" }}
                    elevation={0}
                  ></Paper>
                  <ListItem
                    divider={true}
                    key={i}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography variant="h6">{post.title}</Typography>
                    <Typography variant="body1">{post.subTitle}</Typography>
                  </ListItem>
                </Box>
                <Box
                  sx={{
                    height: "2rem",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginRight: ".5rem",
                  }}
                >
                  <PostTags tags={post.tags!} parentIndex={i} />
                  <ArrowCircleRightOutlined color="primary" />
                </Box>
              </Paper>
            </ListItemButton>
          );
        })}
      </List>
      {limit < props.posts.length ? (
        <Button variant="contained" onClick={showMorePosts}>
          Load More
        </Button>
      ) : loadMoreUsed ? (
        <Button variant="contained" onClick={showLessPosts} color="secondary">
          Show Less
        </Button>
      ) : (
        <></>
      )}
    </>
  );
}
