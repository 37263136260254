import { green, amber } from "@mui/material/colors";
import { PaletteMode } from "@mui/material";

export const themeConfig = (prefersDarkMode: Boolean | undefined) => {
  return {
    palette: {
      mode: (prefersDarkMode ? "dark" : "light") as PaletteMode,
      primary: {
        main: green[600],
        contrastText: "#fff",
        white: "#fff",
        black: "#000",
      },
      secondary: {
        main: amber["A100"],
        secondary: green[100],
      },
      accent: {
        main: "#FFD670",
      },
    },
  };
};
