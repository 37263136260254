import * as React from "react";
import { Container, Paper } from "@mui/material";
import { Posts } from "../";
import { LinkProps } from "../../common/types";
import PostData from "../../data/Posts";

interface Props {
  linkObject: LinkProps;
}

export function Page(props: Props) {
  const filteredPosts = PostData.filter((post) => {
    const linkText = props.linkObject.text.toLowerCase();
    return post.tags.includes(linkText) || linkText === "home";
  });
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 !important",
      }}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "4rem",
        }}
        elevation={2}
      >
        <Paper
          alt={props.linkObject.ctaAltText}
          component="img"
          src={`${props.linkObject.ctaImagePath}`}
          sx={{ width: "100%", marginBottom: "1rem" }}
          elevation={0}
        ></Paper>
      </Paper>
      <Posts posts={filteredPosts} />
    </Container>
  );
}
