const PostData = [
  {
    link: "jb-hunt-park",
    title: "JB Hunt Park",
    category: "trails",
    subTitle:
      "Hidden in the heart of Springdale, this park is home to one of the most excellent disc golf courses in all of Arkansas. A great spot for fishing, trails, baseball, and more.",
    content: [
      {
        alt: "Lake Springdale in Springdale, Arkansas. It's a bright sunny day and there are lots of green trees with very few clouds in the sunny blue sky.",
        path: "images/jb-hunt/jb-lake-wide-medium.jpeg",
      },
      {
        text: `JB Hunt Park is one of the best parks in northwest Arkansas. Only a few miles from I-49, this park is easily accessible via a short drive and provides ample parking whether you're stopping at the nearby Thunder Chicken parking lot or one of the many JB Hunt parking lots. JB Hunt Park is also part of the Razorback Greenway trail system and provides many amenities making it a popular stopping spot to refuel and recharge.`,
      },
      {
        alt: "A sign showing the distance of several way points along the greenway trail system.",
        path: "images/jb-hunt/jb-greenway-sign-medium.jpeg",
      },
      {
        text: `This public park sits on 200 acres of mostly paved trails and paths. JB Hunt Park has two paved trails around 3 miles in length. There are also connected off-road bike trails for the more adventurous. One of the best features of JB Hunt Park is the connected walking bridges that sit over Spring Creek. The main path even features mini-trails and stairs that lead directly to Spring Creek, allowing an easy way to get wet and see Spring Creek up close. `,
      },
      {
        alt: "Stairs leading downward to Spring Creek. You can see the creek in the background. The path leading to the creek is very green.",
        path: "images/jb-hunt/jb-stairs-to-creek-medium.jpeg",
      },
      {
        text: `The star of the park is Lake Springdale itself. The lake has a walking trail and is a trendy spot for fishing. You will likely catch bass, catfish, trout, and bream, and the park has fish limit signs posted. A shaded marina on Lake Springdale itself doubles as a walkway and an excellent spot for fishing. It's easy to run across the terrapins and other aquatic life during the warmer months.`,
      },
      {
        alt: "A fishing sign posted at JB Hunt Park. It lists the 4 types of fish you might catch: Bass, Catfish, Trout, and Bream. It also has the limit of each posted (catch and release, 3, 5, and 25 respectively).",
        path: "images/jb-hunt/jb-fishing-limits-medium.jpeg",
      },
      {
        text: `One of the best draws to JB Hunt Park is the 18-hole disc golf course that will test even the most seasoned disc golfers. The disc golf course, better known as J Beast disc golf course, is well maintained and has many unique challenges, including a 16th hole that requires a throw over a creek. J Beast disc golf course is a top spot for all disc and frisbee golf enthusiasts in the area.`,
      },
      {
        alt: "The sign posted at the start of the J Beast disc golf that's located at JB Hunt Park. It has a map of each hole listed along with red lines indicating the path of each hole.",
        path: "images/jb-hunt/jb-disc-golf-sign-medium.jpeg",
      },
      {
        text: `JB Hunt Park also features numerous amenities, including six softball fields, two playgrounds, and two separate bathrooms. There are pavilions available for rent through the city's website. The park is a favorite in the Northwest Arkansas area and keeps people coming back frequently, whether for a game of frisbee/or disc golf, fishing or using any of the numerous trails or paths.`,
      },
      {
        alt: "A well lit shot of a bench found at JB Hunt Park located along one of the many trails. There are many trees drapping over the trail and the trail is paved with Lake Springdale in the background.",
        path: "images/jb-hunt/jb-trail-bench-medium.jpeg",
      },
    ],
    ctaImage: "images/jb-hunt/jb-lake-wide-medium.jpeg",
    ctaAltText:
      "Lake Springdale in Springdale, Arkansas. It's a bright sunny day and there are lots of green trees with very few clouds in the sunny blue sky.",
    tags: ["trails", "fishing"],
    date: new Date("August 3 2022"),
  },
  {
    link: "centennial-park",
    title: "Centennial Park at Millsap Mountain",
    category: "trails",
    subTitle:
      "The venue for the prestigious 2022 Cyclo-Cross World Championships, this cycling park provides cyclo-cross and mountain biking facilities that cater to riders of all levels of expertise.",
    content: [
      {
        alt: "The first track features at Centennial Park on arrival. This one is a novice mountain bike track showing just the start.",
        path: "images/centennial-park/trail-1.jpg",
      },
      {
        text: `Centennial Park at Millsap Mountain is one of my favorite places to ride in Fayetteville, Arkansas. This beautiful park offers a unique combination of paved and natural-surface trails, world-class cyclo-cross, mountain bikes, running courses, and stunning scenery that makes every ride an adventure.`,
      },
      {
        alt: "A giant unique building that features unique angled architecture that serves as the hub for Centennial Park.",
        path: "images/centennial-park/building.jpg",
      },
      {
        text: `Overall, JB`,
      },
      {
        alt: "The start of a race track featuring line numbers painted onto the ground. The track starts as pavement and turns into an off-road course.",
        path: "images/centennial-park/race-lines.jpg",
      },
      {
        text: `But even if you're not a world-class cyclist, Centennial Park has plenty to offer. Its network of paved trails connects seamlessly to Fayetteville's existing 53 miles of shared-use trails, making it easy to explore the city and surrounding areas on two wheels. And if you prefer a more rugged experience, the park's 56 miles of natural-surface trails offer plenty of mountain biking, trail running, and hiking opportunities.`,
      },
      {
        alt: "The parks sign sits in the foreground with the many off-road courses in the background.",
        path: "images/centennial-park/sign-w-bridge.jpg",
      },
      {
        text: `What sets Centennial Park apart from other cycling destinations, however, is its emphasis on building riders' skills and abilities. The park's courses feature multiple surfaces and features, including steep climbs, technical descents, and challenging obstacles, that force riders to adapt and improve their technique. Whether you're a beginner looking to build your confidence or an experienced rider seeking a new challenge, Centennial Park has something for everyone.`,
      },
      {
        alt: "Close up image of a giant tunnel that is part of one of the off-road courses.",
        path: "images/centennial-park/tunnel.jpg",
      },
      {
        text: `Of course, it's not just the trails that make Centennial Park unique. The park is also home to various amenities, including a bike park, pump track, and skills course, that allow riders of all levels to hone their abilities in a safe and supportive environment. And with plenty of picnic areas, restrooms, and other facilities, the park is a great place to spend a day with friends and family, enjoying the beauty of the natural surroundings and the thrill of the ride.`,
      },
      {
        alt: "A close-up image of the Centennial Park sign giving an overview of the park as a whole.",
        path: "images/centennial-park/park-sign.jpg",
      },
      {
        text: `As a resident of Fayetteville and a passionate cyclist, I feel fortunate to have a world-class facility like Centennial Park right in my backyard. Whether you're a local or just passing through, I highly recommend checking out this fantastic park and experiencing all it offers. Centennial Park is truly a cyclist's paradise from the challenging courses to the stunning scenery.`,
      },
    ],
    ctaImage: "images/centennial-park/stairs.jpg",
    ctaAltText:
      "Bike trail featuring stairs running over a giant tunnel that is also part of the trail.",
    tags: ["trails"],
    date: new Date("April 8 2023"),
  },
  {
    link: "lake-fayetteville-trail",
    title: "Lake Fayetteville Trail",
    category: "trails",
    subTitle:
      "You won't believe this hidden gem sitting in the heart of Fayetteville.",
    content: [
      {
        alt: "A wide shot of lake fayetteville from the paved trail that surrounds it. There is a canoe and a sail boat in lake fayetteville. It's a bright sunny day and the dock is visible. There a multiple paddle boats visible at the dock.",
        path: "images/fayetteville-trail/lake-fayetteville-wide.jpg",
      },
      {
        text: `Lake Fayetteville trail is home to one of the most scenic walks in the state. The trail is only a short distance from Thompson Street, making it accessible and an excellent spot for daily walks and rides. Lake Fayetteville trail is part of the Razorback Greenway trail system. There are numerous bathroom buildings on the trail, and plenty of parking is available in multiple parking lots.`,
      },
      {
        alt: "A wide shot of the award winning bridge at fayetteville trail. The bridge has no people on it and the nature in the background is very green. The bridge is paved and has a yellow dotted strip going down the middle of it.",
        path: "images/fayetteville-trail/fayetteville-trail-bridge-wide.jpg",
      },
      {
        text: `The trail features a paved 5.5 miles long multi-use trail that is great for walking and especially for a nice bike ride. The track is nice and wide, accommodating a good mix of people out for a stroll and people looking for a good workout. There's also a 6.9-mile natural trail that runs alongside the paved trail. Multiple parking lots make access to the paved trails very easy, and the location makes it a popular spot at all times of the day. The path is dog and pet friendly.`,
      },
      {
        alt: "A shot of the docks at lake fayetteville. There are paddle boats at the docks and watercraft visible in the background. It's a very sunny day and the water is really blue.",
        path: "images/fayetteville-trail/fayetteville-docks-wide.jpg",
      },
      {
        text: `The trail borders Lake Fayetteville, a gorgeous 450-acre lake that is one of the most excellent lakes in the Northwest Arkansas area. Fishing is widespread, and the lake offers a variety of fish to catch, including trout, bream, bass, and catfish. Lake Fayetteville provides multiple spots to fish from, and it's easy to take advantage of any of the lake's natural coves. Kayaking and other water sports are also prevalent at this lake. Kayaks and paddle boats are available for rent via the city's website. It's common to see watercraft of all sizes during the warmer months.`,
      },
      {
        alt: "A well lit shot of a bench found at JB Hunt Park located along one of the many trails. There are many trees drapping over the trail and the trail is paved with Lake Springdale in the background.",
        path: "images/jb-hunt/jb-trail-bench-medium.jpeg",
      },
      {
        text: `Multiple stops along the trail include a playground, volleyball courts, and bathroom options. The trail also has plenty of park benches and places to stop and relax. Lake Fayetteville trail is one of the favorite parks in northwest Arkansas. The trail hosts multiple races throughout the year, and its central location makes the trail a great spot to connect to many of the other venues in the area. Lake Fayetteville trail is a great park to take the family for an outing, and there are multiple picnic areas. `,
      },
    ],
    ctaImage: "images/fayetteville-trail/lake-fayetteville-wide.jpg",
    ctaAltText:
      "A wide shot of lake fayetteville from the paved trail that surrounds it. There is a canoe and a sail boat in lake fayetteville. It's a bright sunny day and the dock is visible. There a multiple paddle boats visible at the dock.",
    tags: ["trails", "fishing"],
    date: new Date("August 28 2022"),
  },
  {
    link: "jbeast-disc-golf",
    title: "J. Beast Disc Golf Course",
    category: "trails",
    subTitle:
      "This disc golf course has a good mix of challenging holes for all skill levels and ages.",
    content: [
      {
        alt: "A sign containing a map containing all 18 holes and their throw paths.",
        path: "images/jbeast/jbeast-course-map.jpg",
      },
      {
        text: `For disc golf course enthusiasts, J. Beast disc golf course has it all, and it happens to be in the heart of Northwest Arkansas. This disc golf course is located minutes from the highway in Springdale, Arkansas. It also is part of the legendary Razorback Greenway trail system, making it one of the most assessable parks in the area. The course is one of the top spots for disc golf players and provides a good mix of challenging and more accessible holes.`,
      },
      {
        alt: `A single disc golf basket in the middle of a field. The field is very green and it's very sunny out.`,
        path: "images/jbeast/jbeast-single-hole.jpg",
      },
      {
        text: `J. Beast disc golf course is a 54-par disc golf course and makes excellent use of the natural landscape in the Northwest Arkansas area. The terrain makes the holes feel immersive. The course connects to J.B. Hunt Park and shares use of the parking lots and many amenities already available. The holes are well maintained, and the walking paths make it easy to get around.`,
      },
      {
        alt: `The starting block and marker post for hole 5 of the course. It's a very sunny day and the grass surround the block is very green.`,
        path: "images/jbeast/jbeast-hole-5.jpg",
      },
      {
        text: `Since J. Beast disc golf course is part of the public Springdale park system, multiple bathroom buildings run along the course, and all are available within a short walk. There also happens to be a disc golf shop located on the grounds, which makes it an excellent spot for picking up new discs or replacing discs after playing the challenging course which features some of the most difficult holes you'll find on any disc golf course.`,
      },
      {
        alt: `One of the many paved paths that run along the disc golf course that is shared with the connected JB Hunt park.`,
        path: "images/jbeast/jbeast-path.jpg",
      },
      {
        text: `Some of the highlights of J. Beast disc golf course are hole one, which features a collection of deeply nested trees surrounding all sides of the hole. Holes ten and sixteen use the natural creek that runs through the site. Hole sixteen requires you to throw over this creek, and losing a disc in the water can quickly happen if you're not careful. `,
      },
      {
        alt: `Hole 1 of the course which shows the basket surrounded by a circle of thick brush and trees.`,
        path: "images/jbeast/jbeast-hole-1.jpg",
      },
      {
        text: `J. Beast disc golf course is an excellent spot for disc golf players of all skill levels, but it tends to favor those with more experience. The back-nine is where the skill level differences start to stand out. If you're an experienced disc golf player or just someone starting, it's a top spot for those in the Northwest Arkansas area looking to get a session in or see one of the most unique disc golf courses.`,
      },
    ],
    ctaImage: "images/jbeast/jbeast-main-sign.jpg",
    ctaAltText: `The main sign posted at the start of the course. Features big bold letters and a clever beast design.`,
    tags: ["trails"],
    date: new Date("September 18 2022"),
  },
  {
    link: "food-trucks-north-college-avenue",
    title: "Food Trucks, North College Avenue",
    category: "food",
    subTitle:
      "Offering some of the most diverse food options in Fayetteville, the food truck scene at North College Avenue is sure to please even the pickiest of eaters.",
    content: [
      {
        alt: "Fabys Mexican food truck which shows a shaded patio in front of the food truck. There is a couple eating and a number of cars parked to the right. Very busy with people in the background.",
        path: "images/food/fabys-mexican-medium.jpeg",
      },
      {
        text: `Food trucks have become an integral part of the Fayetteville food scene, and for a good reason. Not only do they offer a diverse range of food options, but they also provide a unique and immersive dining experience. Visitors can explore a world of flavors at the North College Avenue location without venturing too far. Local chefs often own and operate the food trucks, providing an authentic taste of Fayetteville's culinary talent. Plus, with new food trucks constantly popping up, there's always something new to discover. The food truck scene at North College Avenue encapsulates the essence of Fayetteville's food culture, making it a must-visit destination for anyone looking to get the city's whole experience.`,
      },
      {
        alt: "An image from a shaded picnic table showing two food trucks up-close in the background. There is an empty picnic table in the foreground.",
        path: "images/food/thai-fillipino-food-trucks-medium.jpeg",
      },
      {
        text: `One of the great things about the food truck scene at North College Avenue is that it offers a fantastic opportunity to explore new cuisines and flavors that may be unfamiliar to visitors. For instance, the Thai food truck may offer traditional dishes such as Pad Thai or Tom Yum Soup, while the Mexican truck may have unique takes on classic Mexican dishes such as tacos or burritos. Visitors can sample a variety of flavors and dishes without committing to a full meal at a traditional restaurant. The food truck scene is also great for discovering new and exciting fusion dishes that blend different culinary traditions, providing an entirely new dining experience.`,
      },
      {
        alt: "Wider image of the food trucks as a whole. Can see a number of food trucks in the background with car parking in the foreground. It is a very sunny day with lots of commotion in the background near the food trucks.",
        path: "images/food/food-trucks-parking-wide-medium.jpeg",
      },
      {
        text: `The location offers plenty of outdoor seating options, including patio tables and shaded spots, providing the perfect place to enjoy your meal. The area is clean, and each food truck takes great pride in delivering an exceptional experience for every customer. The food court stays busy, ensuring that the food offerings remain fresh and there's minimal wait when ordering. Most food trucks accept credit or cash; many offer online ordering or delivery services. The city's commitment to diversity and community is evident in every aspect of its culinary and cultural offerings, making it a destination worth exploring.`,
      },
      {
        alt: "A paved trail that runs along the side of Lake Fayetteville. It's a wide trails with a yellow dotted strip running down the middle of it. It's empty and very sunny outside.",
        path: "images/food/fayetteville-trail-medium.jpeg",
      },
      {
        text: `Located just minutes from Lake Fayetteville, North College Avenue is perfect for a romantic date or family outing. Visitors can take advantage of the numerous trails that Lake Fayetteville provides, providing a beautiful backdrop for a post-meal stroll. With its wide variety of options and exceptional quality, the food truck scene at North College Avenue is a favorite among locals and tourists alike, ensuring that visitors keep coming back for more. Whether you're exploring new flavors or simply looking for a satisfying meal, Fayetteville's food scene will surely impress. From food trucks to fine dining, the city offers a culinary adventure no matter what you're in the mood for.`,
      },
    ],
    ctaImage: "images/food/food-trucks-1-cars.jpg",
    ctaAltText:
      "A bunch of cars parked in front of a string of food trucks. It's a bright sunny day and the food trucks are very busy.",
    tags: ["food"],
    date: new Date("April 23 2023"),
  },
  {
    link: "jb-hunt-park-bike-trail",
    title: "JB Hunt Park Bike Trail",
    category: "trails",
    subTitle:
      "Grab your helmet, hop on your bike, and come explore the beautiful JB Hunt Park Bike Trail today. Whether you're a seasoned cyclist or just starting out, this trail offers something for everyone.",
    content: [
      {
        alt: "Lake Springdale in Springdale, Arkansas. It's a bright sunny day and there are lots of green trees with very few clouds in the sunny blue sky.",
        path: "images/jb-hunt/jb-lake-wide-medium.jpeg",
      },
      {
        text: `JB Hunt Park is an excellent destination for bike enthusiasts seeking a fun and challenging ride. The 200-acre park in Springdale, Northwest Arkansas, offers a wide range of amenities and activities suitable for all ages and interests. However, one of the park's most notable attractions is the Thunder Chicken parking lot and trail system, featuring a challenging 3-mile paved bike path.`,
      },
      {
        alt: "A sign showing the distance of several way points along the greenway trail system.",
        path: "images/jb-hunt/jb-greenway-sign-medium.jpeg",
      },
      {
        text: `The Thunder Chicken trail system is known for its rugged terrain, making it an exhilarating and unique experience for riders of all skill levels. The trail's demanding nature challenges experienced riders, while novice riders can use it to improve their skills. The path's difficulty level varies throughout, offering various challenges that cater to different riding preferences.`,
      },
      {
        alt: "Stairs leading downward to Spring Creek. You can see the creek in the background. The path leading to the creek is very green.",
        path: "images/jb-hunt/jb-stairs-to-creek-medium.jpeg",
      },
      {
        text: `The bike path connects to the Razorback Greenway trail system, providing visitors access to several parks and trails in Fayetteville, Lowell, and Bentonville. The trail system is well-maintained, perfect for those exploring the region's natural beauty while enjoying outdoor activities. The Thunder Chicken trail's difficulty level adds an extra challenge for riders seeking a more exciting adventure.`,
      },
      {
        alt: "A well lit shot of a bench found at JB Hunt Park located along one of the many trails. There are many trees drapping over the trail and the trail is paved with Lake Springdale in the background.",
        path: "images/jb-hunt/jb-trail-bench-medium.jpeg",
      },
      {
        text: `JB Hunt Park's Lake Springdale is another highlight, surrounded by a paved bike trail that offers easy access to the lake's many amenities. The lake is an excellent spot for fishing, and visitors can expect to catch bass, catfish, trout, and bream. As visitors bike along the route, they'll encounter several streams and creeks teeming with aquatic life, providing a peaceful and tranquil setting for a ride.`,
      },
      {
        alt: "The sign posted at the start of the J Beast disc golf that's located at JB Hunt Park. It has a map of each hole listed along with red lines indicating the path of each hole.",
        path: "images/jb-hunt/jb-disc-golf-sign-medium.jpeg",
      },
      {
        text: `In addition to the challenging bike trail, JB Hunt Park offers several walking bridges that provide stunning views of Spring Creek, a popular spot for picnics and relaxation. Visitors can also explore other park areas, including playgrounds, sports fields, and picnic areas. The park provides ample parking for visitors, making accessing the various amenities and attractions easy.`,
      },
      {
        alt: "A fishing sign posted at JB Hunt Park. It lists the 4 types of fish you might catch: Bass, Catfish, Trout, and Bream. It also has the limit of each posted (catch and release, 3, 5, and 25 respectively).",
        path: "images/jb-hunt/jb-fishing-limits-medium.jpeg",
      },
      {
        text: `JB Hunt Park provides a range of amenities to ensure visitors have an enjoyable experience. The park offers bathrooms and rest areas, allowing visitors to take a break and refuel during their ride. The bike paths are separate from the walking trails, ensuring visitors can explore the park without interfering with others.`,
      },
      {
        text: `In conclusion, JB Hunt Park is a must-visit destination for anyone seeking a fun and challenging bike ride in Northwest Arkansas. The park's difficult terrain, stunning scenery, and easy access to Lake Springdale make it a unique and memorable experience suitable for all riders. Whether planning a day trip, weekend getaway, or extended vacation, JB Hunt Park is an ideal location for all bike enthusiasts. With its challenging bike trail, stunning natural scenery, and various amenities, JB Hunt Park offers all riders a fun and exciting adventure. So, grab your bike, head to JB Hunt Park, and prepare for an unforgettable experience!`,
      },
    ],
    ctaImage: "images/jb-hunt/jb-trail-bench-medium-cta.jpeg",
    ctaAltText:
      "A well lit shot of a bench found at JB Hunt Park located along one of the many trails. There are many trees drapping over the trail and the trail is paved with Lake Springdale in the background.",
    tags: ["trails", "fishing"],
    date: new Date("April 16 2023"),
  },
  {
    link: "bryce-davis-park",
    title: "Bryce Davis Park",
    category: "trails",
    subTitle:
      "A natural oasis in the heart of Fayetteville - discover the beauty and adventure of our this hidden gem!",
    content: [
      {
        alt: "A swampy pond found at Bryce Davis Park.",
        path: "images/bryce-davis/swamp.jpg",
      },
      {
        text: `Bryce Davis Park is a lovely green space in the heart of Fayetteville, Arkansas. This 26-acre park offers visitors a variety of amenities and activities, making it a popular destination for locals and tourists alike.`,
      },
      {
        alt: "Woody forest at Bryce Davis Park, with lots of dead leaves and underbrush.",
        path: "images/bryce-davis/forest.jpg",
      },
      {
        text: `One of the most striking features of Bryce Davis Park is its natural beauty. The park has several mature trees, providing ample shade during the hot summer. Several open grassy areas are also perfect for picnics or impromptu frisbee games. Bryce Davis Park has a beautiful gazebo on the pond's edge, providing an excellent spot for fishing and catching a little shade during the warmer summer. A multi-use trail winds through the park's natural areas, providing a perfect opportunity for walkers, runners, and cyclists to enjoy the park's natural beauty.`,
      },
      {
        alt: "Lovely image of the pond at Bryce Davis Park. A bench sits in the foreground.",
        path: "images/bryce-davis/pond.jpg",
      },
      {
        text: `Bryce Davis Park offers plenty for those looking to become more active. One of the main features of Bryce Davis Park is its half-basketball court. This half-court provides an excellent opportunity for visitors to exercise and shoot hoops. Another popular attraction is the 3-acre dog park, perfect for furry friends to run and play. The dog park is top-rated, and since Bryce Davis Park connects to the local neighborhoods, this dog park always provides an opportunity to meet a new furry friend. There are also several walking trails, which wind through the park's wooded areas and offer a peaceful respite from the hustle and bustle of the city.`,
      },
      {
        alt: "A family of terrapins on a log in the lake at Bryce Davis Park.",
        path: "images/bryce-davis/turtles.jpg",
      },
      {
        text: `In addition to its recreational amenities, Bryce Davis Park hosts several community events yearly. These events include concerts, festivals, and outdoor movie screenings and provide a fun and festive atmosphere for visitors of all ages. The park also has several pavilions at the dog park and pond, which are great places for visitors to host picnics or events. In addition, there are plenty of picnic areas throughout the park, making it a great place to enjoy a meal or a snack. Restrooms are also available on-site, making it easy for visitors to take care of their needs while enjoying the park.`,
      },
      {
        alt: "A paved trail that runs by the dog park found at Bryce Davis Park.",
        path: "images/bryce-davis/walking-path.jpg",
      },
      {
        text: `Bryce Davis Park is a beautiful place to spend a day outdoors in Fayetteville. Whether you're looking to play sports, relax in the shade, or attend a community event, this park has something for everyone.`,
      },
    ],
    ctaImage: "images/bryce-davis/pond.jpg",
    ctaAltText:
      "Lovely image of the pond at Bryce Davis Park. A bench sits in the foreground.",
    tags: ["trails", "fishing"],
    date: new Date("March 14 2023"),
  },
  {
    link: "lake-fayetteville-bike-trail",
    title: "Lake Fayetteville Bike Trail",
    category: "trails",
    subTitle:
      "Come pedal along the picturesque Lake Fayetteville Bike Trail and discover the sights, sounds, and serenity of this stunning lake. Whether you're seeking a leisurely ride or a heart-pumping challenge, this trail has it all. ",
    content: [
      {
        alt: "A view from over the award winning bridge that is on the trail. It's a creek bed that is very dry and very rocky.",
        path: "images/fayetteville-trail/fayetteville-trail-bridge-view.jpg",
      },
      {
        text: `If you're looking for an unforgettable cycling experience in Northwest Arkansas, look no further than the Lake Fayetteville Trail. This trail is a part of the renowned Razorback Greenway trail system and is one of the most breathtaking bike rides in the state. But its stunning natural beauty sets the Lake Fayetteville Trail apart. As you pedal along the paved path, you'll be surrounded by lush greenery and panoramic lake views. The trail is a treat for the senses, with fragrant wildflowers, towering trees, and nature's soothing sounds. The 5.5-mile paved multi-use trail is easily accessible and perfect for daily rides. Whether you're looking for a leisurely stroll or a challenging workout, the path can accommodate all levels of cycling expertise.`,
      },
      {
        alt: "A shot of the docks at lake fayetteville. There are paddle boats at the docks and watercraft visible in the background. It's a very sunny day and the water is really blue.",
        path: "images/fayetteville-trail/fayetteville-docks-wide.jpg",
      },
      {
        text: `Located just a stone's throw from Thompson Street, this paved multi-use trail is easily accessible and perfect for daily rides. Whether you're looking for a stroll or a challenging workout, the path can accommodate all levels of cycling expertise. But that's not all - for the adventurous mountain bikers, a 6.9-mile natural trail runs parallel to the paved path, offering an unparalleled experience in the great outdoors. Accessing the trail is easy, with multiple parking lots available. As you explore the trail's natural beauty, you'll feel a sense of tranquility and peace that can be hard to come by in the hustle and bustle of daily life. Whether you're a resident or a visitor, the trail is a must-see attraction that showcases the best of Northwest Arkansas and the city of Fayetteville.`,
      },
      {
        alt: "A wide shot of the award winning bridge at fayetteville trail. The bridge has no people on it and the nature in the background is very green. The bridge is paved and has a yellow dotted strip going down the middle of it.",
        path: "images/fayetteville-trail/fayetteville-trail-bridge-wide.jpg",
      },
      {
        text: `If you're planning a family outing, this trail is also pet-friendly and has plenty of spots to relax and enjoy the stunning scenery. Make a pit stop at the playground, volleyball courts, or one of the many bathroom options along the trail. The trail borders Lake Fayetteville, a 450-acre lake renowned for its fishing opportunities. With trout, bream, bass, and catfish aplenty, it's a fishing enthusiast's dream come true. And if you're looking for an adrenaline rush, kayaking and other water sports are popular during the warmer months.`,
      },
      {
        alt: "A wide shot of lake fayetteville from the paved trail that surrounds it. There is a canoe and a sail boat in lake fayetteville. It's a bright sunny day and the dock is visible. There a multiple paddle boats visible at the dock.",
        path: "images/fayetteville-trail/lake-fayetteville-wide.jpg",
      },
      {
        text: `But wait, there's more! Lake Fayetteville Trail hosts multiple races throughout the year, making it an ideal spot for cyclists and runners alike. And with its central location, the park is also an excellent starting point for exploring the many other exciting venues in the area. Overall, the Lake Fayetteville Trail is a must-visit destination for anyone looking to experience the natural beauty of Northwest Arkansas. Its central location in the heart of the city of Fayetteville makes its picturesque views, accessibility, and numerous amenities, a favorite spot for cycling enthusiasts like myself. Pack a picnic and make a day of it - you won't be disappointed!`,
      },
    ],
    ctaImage: "images/fayetteville-trail/fayetteville-trail-bridge-wide.jpg",
    ctaAltText:
      "A wide shot of the award winning bridge at fayetteville trail. The bridge has no people on it and the nature in the background is very green. The bridge is paved and has a yellow dotted strip going down the middle of it.",
    tags: ["trails", "fishing"],
    date: new Date("April 17 2023"),
  },
  {
    link: "jb-hunt-park-fishing",
    title: "JB Hunt Park Fishing",
    category: "fishing",
    subTitle:
      "Cast your line and experience the thrill of the catch at one of the best lake's in Northwest Arkansas!",
    content: [
      {
        alt: "Lake Springdale in Springdale, Arkansas. It's a bright sunny day and there are lots of green trees with very few clouds in the sunny blue sky.",
        path: "images/jb-hunt/jb-lake-wide-medium.jpeg",
      },
      {
        text: `JB Hunt Park is an excellent destination for outdoor enthusiasts seeking a fun and challenging experience, especially those interested in fishing. The 200-acre park in Springdale, Northwest Arkansas, offers various amenities and activities suitable for all ages and interests. However, one of the park's most notable attractions for anglers is the Thunder Chicken parking lot and trail system, featuring a challenging 3-mile paved path. 
        The Thunder Chicken trail system is known for its rugged terrain, making it an exhilarating and unique experience for visitors of all skill levels. The path's demanding nature challenges experienced hikers and walkers, while novice visitors can use it to improve their skills. The trail's difficulty level varies throughout, offering various challenges that cater to different preferences.`,
      },
      {
        alt: "A sign showing the distance of several way points along the greenway trail system.",
        path: "images/jb-hunt/jb-greenway-sign-medium.jpeg",
      },
      {
        text: `The path connects to the Razorback Greenway trail system, providing visitors access to several parks and trails in Fayetteville, Lowell, and Bentonville. The trail system is well-maintained, perfect for those exploring the region's natural beauty while enjoying outdoor activities. The Thunder Chicken trail's difficulty level adds an extra challenge for visitors seeking a more exciting adventure and an opportunity to explore the park's excellent fishing spots.`,
      },
      {
        alt: "Stairs leading downward to Spring Creek. You can see the creek in the background. The path leading to the creek is very green.",
        path: "images/jb-hunt/jb-stairs-to-creek-medium.jpeg",
      },
      {
        text: `JB Hunt Park's Lake Springdale is a highlight for anglers, surrounded by a paved path that offers easy access to the lake's many fishing amenities. Visitors can expect to catch bass, catfish, trout, and bream, making it an excellent spot for fishing enthusiasts. As visitors walk along the route, they'll encounter several streams and creeks teeming with aquatic life, providing a peaceful and tranquil setting for a fishing adventure.
        In addition to the challenging path and fishing opportunities, JB Hunt Park offers several walking bridges that provide stunning views of Spring Creek, a popular spot for picnics and relaxation. Visitors can also explore other park areas, including playgrounds, sports fields, and picnic areas. The park provides ample parking for visitors, making accessing the various amenities and attractions easy.`,
      },
      {
        alt: "A well lit shot of a bench found at JB Hunt Park located along one of the many trails. There are many trees drapping over the trail and the trail is paved with Lake Springdale in the background.",
        path: "images/jb-hunt/jb-trail-bench-medium.jpeg",
      },
      {
        text: `JB Hunt Park provides a range of amenities to ensure visitors have an enjoyable fishing experience. The park offers bathrooms and rest areas, allowing visitors to take a break and refuel during their fishing trip. The paths are separate from the walking trails, ensuring visitors can explore the park without interfering with others.`,
      },
      {
        alt: "A fishing sign posted at JB Hunt Park. It lists the 4 types of fish you might catch: Bass, Catfish, Trout, and Bream. It also has the limit of each posted (catch and release, 3, 5, and 25 respectively).",
        path: "images/jb-hunt/jb-fishing-limits-medium.jpeg",
      },
      {
        text: `In conclusion, JB Hunt Park is a must-visit destination for anyone seeking a fun and challenging outdoor experience with excellent fishing opportunities in Northwest Arkansas. The park's rugged terrain, stunning scenery, and easy access to Lake Springdale make it a unique and memorable experience suitable for all fishing enthusiasts. Whether planning a day trip, weekend getaway, or extended vacation, JB Hunt Park is an ideal location for all outdoor anglers. With its challenging path, stunning natural scenery, and various amenities, JB Hunt Park offers all visitors a fun and exciting fishing adventure. So, head to JB Hunt Park with your fishing gear and prepare for an unforgettable fishing experience!`,
      },
    ],
    ctaImage: "images/jb-hunt/jb-fishing-limits-medium.jpeg",
    ctaAltText:
      "A fishing sign posted at JB Hunt Park. It lists the 4 types of fish you might catch: Bass, Catfish, Trout, and Bream. It also has the limit of each posted (catch and release, 3, 5, and 25 respectively).",
    tags: ["trails", "fishing"],
    date: new Date("April 23 2023"),
  },
  {
    link: "razorback-greenway",
    title: "Razorback Regional Greenway",
    category: "trails",
    subTitle:
      "Are you looking for an exciting and adventurous way to explore Northwest Arkansas? Look no further than the Razorback Regional Greenway trail system! With over 40 miles of paved trails, you can bike, run, or walk through some of the most beautiful scenery in the area.",
    content: [
      {
        alt: "A wide shot of lake fayetteville from the paved trail that surrounds it. There is a canoe and a sail boat in lake fayetteville. It's a bright sunny day and the dock is visible. There a multiple paddle boats visible at the dock.",
        path: "images/fayetteville-trail/lake-fayetteville-wide.jpg",
      },
      {
        text: `Northwest Arkansas is a haven for outdoor enthusiasts, with miles of paved trails, world-class biking trails, serene lakes, and beautiful parks. One of the highlights of the region's trail system is the Razorback Regional Greenway, a 37-mile paved trail that connects Bentonville to Fayetteville. The Razorback Regional Greenway is more than just a paved trail. It's a community asset that brings people together and enhances the quality of life in the region. The trail was developed in partnership with local government agencies and nonprofit organizations to provide safe, accessible, and enjoyable transportation options for residents and visitors.`,
      },
      {
        alt: "A sign showing the distance of several way points along the greenway trail system.",
        path: "images/jb-hunt/jb-greenway-sign-medium.jpeg",
      },
      {
        text: `The trail starts in Bentonville, home to the world headquarters of Walmart, and ends in Fayetteville, home to the University of Arkansas. Along the way, the trail passes through several other cities and towns, including Rogers, Lowell, Springdale, and Johnson. One of the unique features of the Razorback Regional Greenway is the diversity of landscapes it passes through. The trail meanders through urban areas, quiet neighborhoods, and scenic parks, offering a glimpse of the region's rich history and natural beauty.`,
      },
      {
        alt: "A paved trail that runs along the side of Lake Fayetteville. It's a wide trails with a yellow dotted strip running down the middle of it. It's empty and very sunny outside.",
        path: "images/food/fayetteville-trail-medium.jpeg",
      },
      {
        text: `One of the trail's highlights is Lake Bella Vista, just a few miles from Bentonville. The lake is a popular spot for fishing, kayaking, and paddleboarding, and several access points are along the trail. Other lakes along the route include Lake Atalanta in Rogers and Lake Fayetteville in Fayetteville, both of which offer opportunities for fishing, boating, and picnicking. In addition to the lakes, the Razorback Regional Greenway passes through several parks, including Compton Gardens and Crystal Bridges Museum of American Art in Bentonville and Walker Park, and Wilson Park in Fayetteville. These parks offer a variety of amenities, including playgrounds, picnic areas, and outdoor art installations, making them great destinations for families and visitors.`,
      },
      {
        alt: "A wide shot of the award winning bridge at fayetteville trail. The bridge has no people on it and the nature in the background is very green. The bridge is paved and has a yellow dotted strip going down the middle of it.",
        path: "images/fayetteville-trail/fayetteville-trail-bridge-wide.jpg",
      },
      {
        text: `Of course, the main attraction of the Razorback Regional Greenway is biking. The trail is designed for cyclists of all levels, from beginners to advanced riders. The trail is flat, with a few gentle hills and several bike rental shops along the route. Cyclists can also take advantage of the trail's numerous rest areas and water fountains to take a break and refuel. The Razorback Regional Greenway offers several challenging sections for advanced riders, including a steep climb up Mount Kessler in Fayetteville. The trail also connects to several world-class mountain biking trails, including the Slaughter Pen Trail System in Bentonville and the Mount Kessler Trail System in Fayetteville.`,
      },
      {
        alt: "A well lit shot of a bench found at JB Hunt Park located along one of the many trails. There are many trees drapping over the trail and the trail is paved with Lake Springdale in the background.",
        path: "images/jb-hunt/jb-trail-bench-medium.jpeg",
      },
      {
        text: `In conclusion, the Razorback Regional Greenway is a unique and valuable asset to the Northwest Arkansas community. The trail system provides a safe and enjoyable way for residents and visitors to explore the region's natural beauty and rich history. Whether you're a seasoned cyclist or a casual rider, there's something for everyone to enjoy on the Razorback Regional Greenway. So grab your bike, put on your helmet, and hit the trail for an unforgettable outdoor adventure.`,
      },
    ],
    ctaImage: "images/jb-hunt/jb-bridge.jpeg",
    ctaAltText:
      "A bridge at JB Hunt Park that is going over a small river/stream. It is very sunny and just a few clouds in the sky.",
    tags: ["trails", "fishing"],
    date: new Date("April 26 2023"),
  },
  {
    link: "coler-mountain-bike-preserve",
    title: "Coler Mountain Bike Preserve",
    category: "trails",
    subTitle:
      "Gear up, embrace the thrill, and embark on an unforgettable cycling adventure at Coler Mountain Bike Preserve.",
    content: [
      {
        text: `The meticulously designed trails at Coler Mountain Bike Preserve offer an endless array of challenges and rewards, making it a playground for riders of all levels. From the adrenaline-inducing downhill sections to the seamless flow trails that allow you to dance with your bike, every ride at Coler is an opportunity to push your limits and experience the sheer joy of cycling. With its awe-inspiring natural beauty and the sense of freedom that comes with exploring its vast expanse, Coler Mountain Bike Preserve is a destination that will leave an indelible mark on the heart of every cycling enthusiast.

        Nestled just a stone's throw away from downtown Bentonville, Coler Mountain Bike Preserve gives riders the remarkable sensation of venturing deep into the heart of the Ozarks. It's hard to believe that such an incredible cycling experience can be found merely two miles away from urban surroundings. The trails at Coler offer a wonderful mix of flowy jump lines and challenging rocky sections that truly put your skills to the test. As you conquer these trails, you'll find yourself drawn to "The Hub," a vibrant hub of activity located near the mountain's summit where you can bask in the camaraderie of fellow riders.
        
        What sets Coler Mountain Bike Preserve apart is the impeccable maintenance and world-class operation standards evident in every aspect of the park. From trail design to upkeep, Coler surpasses expectations, making it a true mecca for cycling enthusiasts in Northwest Arkansas. No matter your skill level or riding preferences, Coler caters to your every need, offering a diverse range of options that keep you coming back for more. Coler has it all, whether you crave the rush of downhill descents, the flowy joy of perfectly sculpted trails, or the technical challenges that push your limits.
        
        But the fun doesn't stop there. Coler Mountain Bike Preserve goes above and beyond by providing camping opportunities for riders who want to immerse themselves fully in this cycling wonderland. The campground offers 16 hike-in tent sites, each perched on elevated wooden platforms, providing a unique camping experience surrounded by the beauty of nature. Five van sites are also available for those who prefer a more mobile camping setup. With the campground open year-round, you can plan your cycling getaway whenever the inspiration strikes.
        
        Coler Mountain Bike Preserve is more than just a destination; it's a pilgrimage for cycling enthusiasts. With its world-class trails, breathtaking scenery, and camping facilities that blend comfort with nature, Coler is the ultimate haven for riders seeking the thrill of the ride. Whether you're a seasoned cyclist searching for the next adrenaline-pumping adventure or a passionate rider eager to explore new horizons, Coler Mountain Bike Preserve promises an unforgettable journey that will leave you craving more. Saddle up and get ready to experience the cycling paradise that is Coler Mountain Bike Preserve.`,
      },
    ],
    ctaImage: "images/centennial-park/race-lines.jpg",
    ctaAltText:
      "The start of a race track featuring line numbers painted onto the ground. The track starts as pavement and turns into an off-road course.",
    tags: ["trails"],
    date: new Date("May 19 2023"),
  },
];

module.exports = PostData;
