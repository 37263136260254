export const LinksArray = [
  {
    text: "Home",
    link: "/",
    ctaImagePath: "images/jb-hunt/jb-bridge.jpeg",
    ctaAltText:
      "A bridge at JB Hunt Park that is going over a small river/stream. It is very sunny and just a few clouds in the sky.",
  },
  {
    text: "Food",
    link: "/food",
    ctaImagePath: "images/food/food-trucks-1-cars.jpg",
    ctaAltText:
      "A bunch of cars parked in front of a string of food trucks. It's a bright sunny day and the food trucks are very busy.",
  },
  {
    text: "Trails",
    link: "/trails",
    ctaImagePath: "images/fayetteville/fayetteville-trail.jpg",
    ctaAltText:
      "A paved trail that runs along the side of Lake Fayetteville. It's a wide trails with a yellow dotted strip running down the middle of it. It's empty and very sunny outside.",
  },
  {
    text: "Fishing",
    link: "/fishing",
    ctaImagePath: "images/fayetteville/fayetteville-sailboat-canoe.jpg",
    ctaAltText:
      "A picture of a sailboat and people in a kayak on Lake Fayetteville. The weather is very sunny and nice and the lake takes up the majority of the picture.",
  },
];
