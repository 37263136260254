import * as React from "react";
import { Box, Container, Typography } from "@mui/material";

export function Footer() {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: "0 !important",
        paddingRight: "0 !important",
        maxWidth: "100% !important",
      }}
      component="footer"
    >
      <Typography
        component="h4"
        sx={{ fontSize: ".75rem", marginBottom: ".25rem" }}
        variant="h4"
      >
        {true ? "" : "Copyright &copy; {new Date().getFullYear()} NameOfTheLLC"}
      </Typography>
      <Box
        sx={{ bgcolor: "primary.main", height: ".25rem", width: "100%" }}
      ></Box>
      <Box
        sx={{ bgcolor: "secondary.main", height: ".5rem", width: "100%" }}
      ></Box>
    </Container>
  );
}
